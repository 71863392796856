<template>
  <div class="second-background">
    <div class="col-md-12 center-max-1320-px my-3 py-3 px-0">
      <nav class="navbar navbar-expand-md navbar-dark">
        <h1 class="navbar-brand ml-0 pl-0" href="#">Member's Area Menu</h1>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarSupportedContent" class="collapse navbar-collapse justify-content-center">
          <ul class="navbar-nav">
            <li
              class="nav-item mr-2"
              :class="{ active: currentPath == '/members-area/vpn-client' }"
            >
              <router-link class="nav-link" to="/members-area/vpn-client">VPN Client</router-link>
            </li>
            <li
              class="nav-item mr-2"
              :class="{ active: currentPath == '/members-area/password-change' }"
            >
              <router-link class="nav-link" to="/members-area/password-change"
                >Change Password</router-link
              >
            </li>
            <li class="nav-item mr-2" :class="{ active: currentPath == '/members-area/cancel' }">
              <router-link class="nav-link" to="/members-area/cancel"
                >Cancel Subscription</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { useUrl } from '@/app/router';

export default {
  name: 'MemberMenu',
  inheritAttrs: false,
  setup() {
    const { currentPath } = useUrl();
    return { currentPath };
  },
};
</script>
