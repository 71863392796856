<template>
  <div id="member-area-container">
    <MemberMenu />
    <div>
      <div class="container-fluid center-max-1320-px p-3">
        <div class="row flex-sm-nowrap justify-content-center">
          <div class="col-md-6 m-3">
            <div v-if="!cancelSuccessful" class="text-center">
              <h2 class="m-3">Change your password</h2>
              <form @submit.prevent="submit">
                <div class="form-group">
                  <input
                    id="change-password-old"
                    v-model="oldPassword"
                    type="password"
                    required=""
                    pattern=".{8,}"
                    title="8 characters minimum"
                    placeholder="Old Password"
                    class="payment-form-control"
                  />

                  <input
                    id="change-password-new"
                    v-model="newPassword"
                    type="password"
                    required=""
                    pattern=".{8,}"
                    title="8 characters minimum"
                    placeholder="New Password"
                    class="payment-form-control"
                  />

                  <input
                    id="change-password-confirm"
                    v-model="confirmPassword"
                    type="password"
                    required=""
                    pattern=".{8,}"
                    title="8 characters minimum"
                    placeholder="Confirm New Password"
                    class="payment-form-control"
                  />

                  <input
                    type="submit"
                    class="login-submit-button"
                    value="Confirm"
                    :disabled="isSending"
                  />
                </div>
              </form>

              <h6 class="m-3">
                Please be aware that after change you will have to use new password for both this
                portal and VPN Client
              </h6>
              <div v-if="passwordErrorMessage" class="row flex-nowrap justify-content-center m-3">
                <div class="col-md-12 alert alert-danger alert-dismissible" role="alert">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="passwordErrorMessage = null"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 class="alert-heading">We are sorry!</h5>
                  <hr />
                  <p>{{ passwordErrorMessage }}</p>
                </div>
              </div>
            </div>
            <div v-if="changeSuccessful" class="row flex-nowrap justify-content-center m-3">
              <div class="col-md-12 alert alert-success alert-dismissible" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="changeSuccessful = false"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h5 class="alert-heading">Change successful</h5>
                <hr />
                <p>Your password was successfully changed.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import MemberMenu from '@/app/components/MemberMenu';
import { changePassword } from '@/app/api/user';

export default {
  name: 'ChangePassword',
  components: { MemberMenu },
  inheritAttrs: false,
  props: {
    instanceInfo: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const oldPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const isSending = ref(false);
    const passwordErrorMessage = ref(null);
    const changeSuccessful = ref(false);

    const submit = () => {
      passwordErrorMessage.value = null;
      if (oldPassword.value.length < 8) {
        passwordErrorMessage.value = 'Old password must be at least 8 characters';
        return;
      }
      if (newPassword.value.length < 8) {
        passwordErrorMessage.value = 'New password must be at least 8 characters';
        return;
      }
      if (confirmPassword.value !== newPassword.value) {
        passwordErrorMessage.value =
          'New password must be confirmed in [Confirm New Password] field';
        return;
      }

      const changeRequest = {
        oldPassword: oldPassword.value,
        newPassword: newPassword.value,
      };
      console.log(changeRequest);
      changePassword(changeRequest)
        .then(response => {
          changeSuccessful.value = true;
          return response;
        })
        .catch(() => {
          changeSuccessful.value = false;
          passwordErrorMessage.value =
            "Due to technical problem your password can't be changed. Make sure you provide correct current password and try again or contact our support to help you setting up new password.";
        });
    };

    return {
      oldPassword,
      newPassword,
      confirmPassword,
      passwordErrorMessage,
      changeSuccessful,
      submit,
      isSending,
    };
  },
};
</script>
